<template>
  <div class="container form-wrapper-nca py-5">

    <span class="sample-title">Front ЭЦП</span>


    <div v-if="documentData !== null">
      <p>
        <a :href="documentData.file_url + `&access-token=${getCookie('ACCESS_TOKEN')}`" target="_blank">Ссылка на файл</a>
      </p>

      <div v-if="checkSignature.status == 0">
        <div class="col-md-12 text-center">
          <Button icon="pi pi-key" label="Подписать" class="p-button-rounded m-1" @click="sign"/>
          <Button v-if="canCancelDocument" icon="pi pi-times" label="Отклонить документ" class="p-button-rounded p-button-danger m-1"
                  @click="openModal"/>
        </div>
      </div>
      <div v-else-if="checkSignature.status == 1">
        <h5>Документ подписан</h5>
      </div>
      <div v-else>
        <h5>Документ отклонен</h5>
      </div>
    </div>

    <div v-else>
      <p>Файл не найден!</p>
    </div>
  </div>

  <Dialog v-model:visible="modalVisible" modal header="Отклонение документа" :style="{ width: '50%' }"
          :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <div>
      <Textarea class="col-12" v-model="comment" autoResize rows="5" cols="30" placeholder="Причина отклонения"/>
    </div>
    <template #footer>
      <Button label="Отклонить документ" icon="pi pi-times" class="p-button-danger" @click="cancelSignature" autofocus/>
      <Button label="Закрыть" icon="pi pi-check" @click="closeModal" autofocus/>
    </template>
  </Dialog>

</template>

<script>


import {mapActions, mapState} from "vuex";
import {decryptCheckRole, getDecryptedRoles} from "@/utils/helpers/decryptedRoles.helpers";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {

  data() {
    return {
      SOCKET_URL: 'wss://127.0.0.1:13579',
      //SOCKET_URL: 'wss://javascript.info/article/websocket/demo/hello',
      webSocket: null,
      response: null,
      documentData: null,

      signature: null,
      file_id: this.$route.query.file_id || 0,
      category: this.$route.query.category || 0,
      sign_id: 0,

      checkSignature: null,
      modalVisible: false,
      comment: '',
      canCancelDocument: false,

      accessRoles: ['admin', 'dean', 'departmentHead'],
    };
  },
  computed: {
    ...mapState('documentSignature', ['documentSignature_form']),
  },
  methods: {
    getCookie,
    decryptCheckRole,
    ...mapActions('documentSignature', ['GET_BASE64_DOCUMENT', 'POST_SIGNED_DOCUMENT', 'GET_CHECK_SIGNATURE']),

    openModal() {
      this.modalVisible = true
    },

    closeModal() {
      this.modalVisible = false
      this.comment = ''
    },

    async sign() {
      console.log('SIGN')
      await this.request();
    },
    async connect() {
      if (this.webSocket && this.webSocket.readyState < 2) {
        console.log("Reusing the socket connection [state = " + this.webSocket.readyState + "]: " + this.webSocket.url);
        return Promise.resolve(this.webSocket);
      }

      return new Promise((resolve, reject) => {
        this.webSocket = new WebSocket('wss://127.0.0.1:13579');

        this.webSocket.onopen = () => {
          console.log("Socket connection is opened [state = " + this.webSocket.readyState + "]: " + this.webSocket.url);
          resolve(this.webSocket);
        };

        this.webSocket.onerror = (err) => {
          this.unblockScreen();
          console.error("Socket connection error: ", err);
          reject(err);
        };

        this.webSocket.onclose = (event) => {
          if (event.wasClean) {
            console.error("Socket connection is closed");
          } else {
            console.log('Connection error');
            this.openDialog();
          }
          console.log('Code: ' + event.code + ' Reason: ' + event.reason);
        };
      });
    },
    async request() {
      console.log('REQUEST')
      //this.blockScreen();
      let selectedStorages = ['PKCS12']
      let signatureType = "cms"

      let dataToSign = this.documentData.base64;
      let decode = "true"
      let encapsulate = "true"
      let digested = "false"
      let tsaProfile = {}


      let signInfo = {
        "module": "kz.gov.pki.knca.basics",
        "method": "sign",
        "args": {
          "allowedStorages": selectedStorages,
          "format": signatureType,
          "data": dataToSign,
          "signingParams": {decode, encapsulate, digested, tsaProfile},
          "signerParams": {
            "extKeyUsageOids": [],
            "chain": null,
            "iin": "",
            "bin": "",
          },
          "locale": "ru"
        }
      }

      console.log(signInfo, 'signInfo')
      if (selectedStorages.length == 0) {
        delete signInfo.args.allowedStorages;
      }


      return this.connect().then((webSocket) => {
        webSocket.send(JSON.stringify(signInfo));

        return new Promise((resolve) => {
          webSocket.onmessage = async ({data}) => {
            this.response = JSON.parse(data);
            if (this.response != null) {
              console.log('success')
              //this.$el.querySelector("#signature").textContent = this.response.body.result;
              //this.signature = this.response.body.result

              if (this.response.body.result) {
                let form = {
                  cms: this.response.body.result,
                  file_id: this.file_id,
                  status: 1,
                  category: this.category,
                }


                const postRes = await this.POST_SIGNED_DOCUMENT(form)
                if (postRes) {
                  this.documentData = await this.GET_BASE64_DOCUMENT({category: this.category, file_id: this.file_id})
                  this.checkSignature = await this.GET_CHECK_SIGNATURE({file_id: this.file_id, sign_id: this.sign_id})
                  this.$message({title: 'Успешно подписано'})
                }
              }

            }
            //this.unblockScreen();
            resolve(this.response);
          };
        });
      }).catch((err) => {
        //this.unblockScreen();
        console.log(err);
      });
    },
    blockScreen() {
      // Your existing blockScreen logic goes here...
    },
    unblockScreen() {
      // Your existing unblockScreen logic goes here...
    },
    async cancelSignature() {
      const postRes = await this.POST_SIGNED_DOCUMENT({file_id: this.file_id, status: 2, comment: this.comment, category: this.category})
      if (postRes) {
        this.checkSignature = await this.GET_CHECK_SIGNATURE({file_id: this.file_id, sign_id: this.sign_id})
        this.$message({title: 'Успешно отклонено'})
        this.modalVisible = false
      }
    },
    openDialog() {
      if (confirm("Error connecting to NCALayer. Restart NCALayer and press OK") === true) {
        location.reload();
      }
    },
  },
  async mounted() {
    let userRoles = getDecryptedRoles()
    for (let i = 0; i < userRoles.length; i++){
      if (this.accessRoles.includes(userRoles[i])){
        this.canCancelDocument = true
      }
    }
    if (this.canCancelDocument){
      this.sign_id = this.$route.query.sign_id
    }

    this.checkSignature = await this.GET_CHECK_SIGNATURE({file_id: this.file_id, sign_id: this.sign_id})
    this.documentData = await this.GET_BASE64_DOCUMENT({category: this.category, file_id: this.file_id})
  }
};
</script>

<style scoped>
.form-wrapper-nca {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 42px 55px 45px 55px;
}

.sample-title {
  display: block;
  font-size: 39px;
  font-weight: bold;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 44px;
}

.wrap-input {
  width: 100%;
  position: relative;
  /* padding-bottom: 13px; */
  margin-bottom: 27px;
}

.radio-label {
  padding-right: 50px;
}

.form-radio {
  display: flex;
}

.form-radio .form-check {
  margin-right: 40px;
}

.label-input {
  font-size: 13px;
  color: #666666;
  line-height: 1.5;
}

.param-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.param-title .description {
  font-size: 13px;
  color: #666666;
  line-height: 1.5;
}

.sample-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  line-height: 1.2;
}
</style>